import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    country: "",
    countryCode: ""
}

export const countryDataSlice = createSlice({
    name: "countryData",
    initialState,
    reducers: {
        getCountryData: (state, action) => {
            state.country = action.payload.country;
            state.countryCode = action.payload.countryCode;
        }
    }
})

export const { getCountryData } = countryDataSlice.actions

export default countryDataSlice.reducer
