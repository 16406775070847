import { configureStore } from "@reduxjs/toolkit";
import userDataReducer from "../slice/UserSlice/editUserSlice";
import agentDataReducer from "../slice/AgentSlice/editAgentSlice";
import couponDataReducer from "../slice/CouponSlice/editCouponSlice";
import countryReducer from "../slice/CountrySlice/editCountrySlice";
import cashInreducer from "../slice/CashInSlice/editCashInSlice";
import cashOutreducer from "../slice/CashOutSlice/editCashOutSlice";
import collectionpointReducer from "../slice/CollectionPointsSlice/editCollectionPointsSlice";
import exchangeRateReducer from "../slice/ExchangeRateSlice/editExchangeRateSlice";
import faqsReducer from "../slice/FaqsSlice/editFaqsSlice";
import contactReducer from "../slice/ContactSlice/editContactSlice";
import termsAndConditionReducer from "../slice/TermsSlice/editTermsSlice";
import loginSlice from "../slice/login/loginSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginSlice,
    editUserData: userDataReducer,
    editAgentData: agentDataReducer,
    editCouponData: couponDataReducer,
    editCountryData: countryReducer,
    editCashInSlice: cashInreducer,
    editCashOutSlice: cashOutreducer,
    editCollectionPointSlice: collectionpointReducer,
    exchangeRateReducer: exchangeRateReducer,
    faqsReducer: faqsReducer,
    contactReducer: contactReducer,
    termsAndConditionReducer: termsAndConditionReducer,
  },
});
