import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "countryId": "",
    "rate": ""
}

export const exchangeRateDataSlice = createSlice({
    name: "exchangeRateData",
    initialState,
    reducers: {
        getExchangeRateData: (state, action) => {
            state.countryId = action.payload.countryId._id;
            state.rate = action.payload.rate;
        }
    }
})

export const { getExchangeRateData } = exchangeRateDataSlice.actions

export default exchangeRateDataSlice.reducer
