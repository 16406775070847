import config from "../config/config.json";

// Verify-user-by-JWT-start
export const verifyUserByJwt = async (data) => {
  const endPoint = `${config.apiUrl}/auth/jwtToken`;
  try {
    const response = await fetch(endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {
    console.log("ERROR On Verify User By JWT API:", err);
  }
};
// Verify-user-by-JWT-end

// Generate-Token-start
export const generateJwtToken = async (data) => {
  const endPoint = `${config.apiUrl}/auth/generate/token`;
  try {
    const response = await fetch(endPoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const actualData = await response.json();
    return await actualData;
  } catch (err) {
    console.log("ERROR On Create JWT token API:", err);
  }
};
// Generate-Token-end
