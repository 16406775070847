import React, { Suspense, lazy, PureComponent } from "react";
// 3rd-party
import { Switch, Route, Redirect } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { getAdminStorage } from "../services/localStorage";
// Component-for-scroll-to-top
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
// pages
const Login = lazy(() => import("../pages/Login"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const UserManagement = lazy(() => import("../pages/UserManagement"));
const AgentManagement = lazy(() => import("../pages/AgentManagement"));
const PromotionCoupons = lazy(() => import("../pages/PromotionCoupons"));
const OrderList = lazy(() => import("../pages/OrderList"));
const OrderDetails = lazy(() => import("../pages/OrderDetails"));
const Analytics = lazy(() => import("../pages/Analytics"));
const AdminManagement = lazy(() => import("../pages/AdminManagement"));
const Country = lazy(() => import("../pages/Country"));
const CashInMethods = lazy(() => import("../pages/CashInMethods"));
const CashOutMethods = lazy(() => import("../pages/CashOutMethods"));
const CollectionPoints = lazy(() => import("../pages/CollectionPoints"));
const CashManagement = lazy(() => import("../pages/CashManagement"));
const Transactions = lazy(() => import("../pages/Transactions"));
const CurrentBalance = lazy(() => import("../pages/CurrentBalance"));
const WithdrawRequests = lazy(() => import("../pages/WithdrawRequest"));
const WithdrawRequestDetails = lazy(() =>
  import("../pages/WithdrawRequestDetails")
);
const ViewAgentCashDetails = lazy(() =>
  import("../pages/ViewAgentCashDetails")
);
const UserDetails = lazy(() => import("../pages/UserDetails"));

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={<SyncLoader color={"#030870"} size={17} css={spinnerStyle} />}
      >
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            component={(props) =>
              !getAdminStorage() ? (
                <Login {...props} />
              ) : (
                <Redirect to="/dashboard" />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            component={(props) =>
              getAdminStorage() ? <Dashboard {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/user-management"
            component={(props) =>
              getAdminStorage() ? (
                <UserManagement {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/agent-management"
            component={(props) =>
              getAdminStorage() ? (
                <AgentManagement {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/promotion-coupons"
            component={(props) =>
              getAdminStorage() ? (
                <PromotionCoupons {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/order-list"
            component={(props) =>
              getAdminStorage() ? <OrderList {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/withdraw-requests"
            component={(props) =>
              getAdminStorage() ? (
                <WithdrawRequests {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/order-details"
            component={(props) =>
              getAdminStorage() ? (
                <OrderDetails {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/withdraw-request-details"
            component={(props) =>
              getAdminStorage() ? (
                <WithdrawRequestDetails {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/analytics"
            component={(props) =>
              getAdminStorage() ? <Analytics {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/admin-management"
            component={(props) =>
              getAdminStorage() ? (
                <AdminManagement {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/country"
            component={(props) =>
              getAdminStorage() ? <Country {...props} /> : <Redirect to="/" />
            }
          />
          <Route
            exact
            path="/cashin-methods"
            component={(props) =>
              getAdminStorage() ? (
                <CashInMethods {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/cashout-methods"
            component={(props) =>
              getAdminStorage() ? (
                <CashOutMethods {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/cash-management"
            component={(props) =>
              getAdminStorage() ? (
                <CashManagement {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/collection-points"
            component={(props) =>
              getAdminStorage() ? (
                <CollectionPoints {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/transactions"
            component={(props) =>
              getAdminStorage() ? (
                <Transactions {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/current-balance"
            component={(props) =>
              getAdminStorage() ? (
                <CurrentBalance {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/view-agent-cash-details"
            component={(props) =>
              getAdminStorage() ? (
                <ViewAgentCashDetails {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            exact
            path="/view-user-details"
            component={(props) =>
              getAdminStorage() ? (
                <UserDetails {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
        </Switch>
      </Suspense>
    </>
  );
}
