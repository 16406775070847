import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "name": "",
    "countryId": "",
    "details": "",
    "collectionPoint": false
}

export const cashInDataSlice = createSlice({
    name: "cashInData",
    initialState,
    reducers: {
        getCashInData: (state, action) => {
            state.name = action.payload.name;
            state.countryId = action.payload.countryId._id;
            state.details = action.payload.details;
            state.collectionPoint = action.payload.collectionPoint;
        }
    }
})

export const { getCashInData } = cashInDataSlice.actions

export default cashInDataSlice.reducer
