import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "name": "",
    "phoneNo": "",
    "address": "",
    "countryId": ""
}

export const contactDataSlice = createSlice({
    name: "contactData",
    initialState,
    reducers: {
        getContactData: (state, action) => {
            state.name = action.payload.name;
            state.countryId = action.payload.countryId._id;
            state.address = action.payload.address;
            state.phoneNo = action.payload.phoneNo;
        }
    }
})

export const { getContactData } = contactDataSlice.actions

export default contactDataSlice.reducer
