import React, { useState, useEffect } from "react";
import "../node_modules/bootstrap-4-react/dist/bootstrap-4-react";
import Routes from "./routes/routes";
// 3rd-party
// Model
import TokenExpireModel from "./components/Model/TokenExpire";
// services
import { verifyUserByJwt } from "./services/auth";
// slice
import { loginSignal } from "./slice/login/loginSlice";
//redux
import { useSelector, useDispatch } from "react-redux";

function App() {
  const [tokenModel, setTokenModel] = useState(false);
  const { signal } = useSelector((state) => state.loginStatus);
  console.log("signal", signal);
  const dispatch = useDispatch();

  //fetching data through token start
  const fetchAndVerify = async (token) => {
    const params = {
      token: token,
    };
    const response = await verifyUserByJwt(params).then((data) => {
      return data;
    });
    console.log("Response from token verify Api: ", response);
    if (response.status === "200") {
    } else {
      setTokenModel(true);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("TOKEN");

    if (signal) {
      dispatch(loginSignal(false));
      if (token) {
        console.log("Token available: ", token);
        fetchAndVerify(token);
      }
    }
  }, [signal]);
  //fetching admin data through token end
  return (
    <>
      <TokenExpireModel show={tokenModel} onHide={() => setTokenModel(false)} />
      <Routes />
    </>
  );
}

export default App;
