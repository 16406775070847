import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "detail": ""
}

export const termsDataSlice = createSlice({
    name: "termsData",
    initialState,
    reducers: {
        getTermsData: (state, action) => {
            state.detail = action.payload.detail;
        }
    }
})

export const { getTermsData } = termsDataSlice.actions

export default termsDataSlice.reducer
