import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "name": "",
    "contactName": "",
    "contactNumber": "",
    "cashOutMethod": "",
    "cashInMethod": ""
}

export const collectionPointsDataSlice = createSlice({
    name: "collectionPointData",
    initialState,
    reducers: {
        getCollectionPointData: (state, action) => {
            state.name = action.payload.name;
            state.contactName = action.payload.contactName;
            state.contactNumber = action.payload.contactNumber;
        }
    }
})

export const { getCollectionPointData } = collectionPointsDataSlice.actions

export default collectionPointsDataSlice.reducer
