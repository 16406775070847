import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  code: "",
  discount: "",
  duration: "",
  created: "",
  redeemTill: "",
  countryId: "",
  collectionPoints: [],
};

export const couponDataSlice = createSlice({
  name: "couponData",
  initialState,
  reducers: {
    getCouponData: (state, action) => {
      state.title = action.payload.title;
      state.code = action.payload.code;
      state.discount = action.payload.discount;
      state.duration = action.payload.duration;
      state.created = action.payload.created;
      state.redeemTill = action.payload.redeemTill;
      state.countryId = action.payload.countryId;
      state.collectionPoints = action.payload.collectionPoints;
    },
  },
});

export const { getCouponData } = couponDataSlice.actions;

export default couponDataSlice.reducer;
