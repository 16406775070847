import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fullName: "",
  email: "",
  phone: "",
  password: "",
};

export const agentDataSlice = createSlice({
  name: "agentData",
  initialState,
  reducers: {
    getAgentData: (state, action) => {
      state.fullName = action.payload.fullName;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.password = action.payload.password;
      //state.type = action.payload.type;
      // state.collectionPointIn =
      //   action.payload.collectionPointIn._id ||
      //   action.payload.collectionPointOut._id;
      // state.collectionPointOut =
      //   action.payload.collectionPointIn._id ||
      //   action.payload.collectionPointOut._id;
      // state.country = action.payload.country._id;
    },
  },
});

export const { getAgentData } = agentDataSlice.actions;

export default agentDataSlice.reducer;
