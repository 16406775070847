import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "name": "",
    "countryId": "",
    "details": "",
    "collectionPoint": false,
    "delivery": false,
    "bankTransfer": false,
    "eWallet": false
}

export const cashOutDataSlice = createSlice({
    name: "cashOutData",
    initialState,
    reducers: {
        getCashOutData: (state, action) => {
            state.name = action.payload.name;
            state.countryId = action.payload.countryId._id;
            state.details = action.payload.details;
            state.collectionPoint = action.payload.collectionPoint;
            state.delivery = action.payload.delivery;
            state.bankTransfer = action.payload.bankTransfer;
            state.eWallet = action.payload.eWallet;
        }
    }
})

export const { getCashOutData } = cashOutDataSlice.actions

export default cashOutDataSlice.reducer
