import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signal: true,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginSignal: (state, action) => {
      state.signal = action.payload;
    },
  },
});

export const { loginSignal } = loginSlice.actions;

export default loginSlice.reducer;
