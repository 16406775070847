import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    "question": "",
    "answer": ""
}

export const faqsDataSlice = createSlice({
    name: "faqsData",
    initialState,
    reducers: {
        getFaqsData: (state, action) => {
            state.question = action.payload.question;
            state.answer = action.payload.answer;
        }
    }
})

export const { getFaqsData } = faqsDataSlice.actions

export default faqsDataSlice.reducer
